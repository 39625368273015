<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <!-- eslint-disable vue/attribute-hyphenation -->
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent>
      <b-row>
        <b-col md="9">
          <div ref="basic-information">
            <Fieldset id="basic-information" :title="$t('Basic Information')">
              <BasicInformation
                id="BI"
                v-model="model"
                :categoryIdErrorText="categoryIdErrorText"
                :trademarkOptions="trademarkOptions"
                :originOptions="originOptions"
                :supplierOptions="supplierOptions"
              />
            </Fieldset>
          </div>
          <div ref="sale-information">
            <Fieldset id="sale-information" :title="$t('Sales Information')">
              <SalesInformation id="SI" v-model="model" />
            </Fieldset>
          </div>
          <div ref="media-management">
            <Fieldset id="media-management" :title="$t('Media Management')">
              <MediaManagement id="MM" v-model="model" />
            </Fieldset>
          </div>
          <div ref="shipping">
            <Fieldset id="shipping" :title="$t('Shipping')">
              <ShippingDetail id="SD" v-model="model" />
            </Fieldset>
          </div>
          <div ref="other-information">
            <Fieldset id="other-information" :title="$t('SEO Optimization')">
              <SEOInfo id="SE" v-model="model" />
            </Fieldset>
          </div>
          <!-- <div id="select-san-block">
            <div class="title-san">
              Đồng bộ sàn
            </div>
            <i>Bằng cách chọn danh mục, bạn sẽ xác nhận đồng bộ sản phẩm lên sàn TMĐT</i>
            <b-row class="mt-1">
              <b-col ref="select-san" md="6">
                <b-form-group label="Tiktok Shop" label-cols-md="12" class="align-items-center">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <img data-v-719f9e23="" src="/tiktok.png" alt="tiktok" width="15" class="head-img-icon">
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="modelTiktok.category_name"
                      v-b-modal.modal-popup-sync-product
                      readonly
                      :placeholder="$t('Select product category')"
                    />
                  </b-input-group>
                  <small v-if="categoryIdErrorText !== null" class="text-danger">{{
                    categoryIdErrorText
                  }}</small>
                  <popup-tiktok v-model="modelTiktok" />
                </b-form-group>
              </b-col>
              <b-col ref="idspe" md="6">
                <b-form-group label="Shopee.vn " label-cols-md="12" class="align-items-center">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <img data-v-719f9e23="" src="/shopee.png" alt="shopee" width="15" class="head-img-icon">
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="modelShopee.category_name"
                      v-b-modal.modal-popup-sync-product-shopee
                      readonly
                      :placeholder="$t('Select product category')"
                    />
                  </b-input-group>
                  <small v-if="categoryIdErrorText !== null" class="text-danger">{{
                    categoryIdErrorText
                  }}</small>
                  <popup-shopee v-model="modelShopee" />
                </b-form-group>
              </b-col>
              <b-col ref="idlaz" md="6">
                <b-form-group label="Lazada.vn " label-cols-md="12" class="align-items-center">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <img data-v-719f9e23="" src="/lazada.png" alt="lazada" width="15" class="head-img-icon">
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="modelLazada.category_name"
                      v-b-modal.modal-popup-sync-product-lazada
                      readonly
                      :placeholder="$t('Select product category')"
                    />
                  </b-input-group>
                  <small v-if="categoryIdErrorText !== null" class="text-danger">{{
                    categoryIdErrorText
                  }}</small>
                  <popup-lazada v-model="modelLazada" />
                </b-form-group>
              </b-col>
            </b-row>
          </div> -->
        </b-col>
        <b-col md="3">
          <div class="position-sticky top">
            <b-card no-body>
              <b-card-body>
                <b-nav
                  vertical
                >
                  <b-nav-item
                    v-for="item in verticalNav" :key="item.id"
                    :active="item.id === verticalNavSelected"
                    @click="pageScrollTo(item.id)"
                  >{{ $t(item.label) }}</b-nav-item>
                </b-nav>
              </b-card-body>
            </b-card>
            <!-- <Fieldset id="hbs" :title="$t('Đồng bộ sàn')">
              <b-nav
                vertical
              >
                <b-nav-item
                  v-for="item in listSan" :key="item.id"
                  :active="item.id === verticalNavSelected"
                  @click="pageScrollTo(item.id)"
                >{{ $t(item.text) }}</b-nav-item>
              </b-nav>
            </Fieldset> -->
            <Fieldset id="status" :title="$t('Status')">
              <b-form-group label="Tag 1" label-cols-md="12">
                <b-form-tags
                  v-model="model.tag1"
                  input-id="tags-remove-on-delete"
                  :input-attrs="{
                    'aria-describedby': 'tags-remove-on-delete-help',
                  }"
                  :placeholder="$t('Ví dụ: Trắng, Đen, ...')"
                  remove-on-delete
                />
              </b-form-group>
              <b-form-group label="Tag 2" label-cols-md="12">
                <b-form-tags
                  v-model="model.tag2"
                  input-id="tags-remove-on-delete"
                  :input-attrs="{
                    'aria-describedby': 'tags-remove-on-delete-help',
                  }"
                  :placeholder="$t('Ví dụ: S, M, XL, ...')"
                  remove-on-delete
                />
              </b-form-group>
              <b-form-group label="Tag 3" label-cols-md="12">
                <b-form-tags
                  v-model="model.tag3"
                  input-id="tags-remove-on-delete"
                  :input-attrs="{
                    'aria-describedby': 'tags-remove-on-delete-help',
                  }"
                  :placeholder="$t('Enter new keyword separated by enter')"
                  remove-on-delete
                />
              </b-form-group>
              <b-form-group :label="$t('Display on/off')+`:`" label-cols-md="6">
                <b-form-checkbox
                  v-model="model.is_active"
                  checked="true"
                  class="custom-control-success"
                  name="check-button"
                  switch
                />
              </b-form-group>
              <b-form-group :label="$t('Warehouse')+`:`" label-cols-md="6">
                <b-form-checkbox
                  v-model="model.is_warehouse"
                  checked="true"
                  class="custom-control-success"
                  name="check-button"
                  switch
                />
              </b-form-group>
              <b-form-group :label="$t('Is highlight')+`:`" label-cols-md="6">
                <b-form-checkbox
                  v-model="model.is_highlight"
                  checked="true"
                  class="custom-control-success"
                  name="check-button"
                  switch
                />
              </b-form-group>
              <!-- <b-form-group :label="$t('Position')+`:`" label-cols-md="6">
                <b-form-input
                  v-model="model.position"
                  :placeholder="$t('Position')"
                  type="number"
                />
              </b-form-group> -->
              <hr>
              <div class="text-right">
                <b-button
                  variant="primary"
                  type="submit"
                  class="ml-2"
                  @click.prevent="validationForm"
                >
                  {{ $t('Create new') }}
                </b-button>
              </div>
            </Fieldset>
            <!-- <Fieldset id="relatedOption" :title="$t('Options')">
              <RelatedOptions
                v-model="model"
                :trademarkOptions="trademarkOptions"
                :originOptions="originOptions"
                :supplierOptions="supplierOptions"
              />
            </Fieldset> -->
          </div>
        </b-col>
      </b-row>
    </b-form>
    <Overplay v-if="isShow" />
    <b-modal
      id="modal-popup-sync-product-status"
      size="lg"
      title="Đồng bộ sản phẩm lên sàn TMĐT"
      no-close-on-backdrop
      hide-footer
    >
      <div class="container-ctrl">
        <div>shopee: {{ modelSync.shopee }}</div>
        <div>tiktok: {{ modelSync.tiktok }}</div>
        <div>lazada: {{ modelSync.lazada }}</div>
        <hr>
        <div class="text-right mt-2">
          <b-button
            variant="outline-primary"
            type="button"
            size="sm"
            @click="closeModal"
          >
            {{ $t('Close') }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </validation-observer>
</template>
<script>
/* eslint-disable array-callback-return */
/* eslint-disable operator-linebreak */
import VueScrollactive from 'vue-scrollactive'
import {
  // BLink,
  BRow,
  BCol,
  BForm,
  BButton,
  BFormCheckbox,
  BFormGroup,
  // BFormInput,
  BCard,
  BCardBody,
  BNav,
  BNavItem,
  // BInputGroupPrepend,
  // BInputGroup,
  BFormTags,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import BasicInformation from '@/views/pages/product/product-add/BasicInformation.vue'
import SalesInformation from '@/views/pages/product/product-add/SalesInformation.vue'
import MediaManagement from '@/views/pages/product/product-add/MediaManagement.vue'
// import RelatedOptions from '@/views/pages/product/product-add/RelatedOption.vue'
import ShippingDetail from '@/views/pages/product/product-add/ShippingDetail.vue'
import Vue from 'vue'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import PopupAddCate from './product-add/components/PopupAddCate.vue'
// import PopupTiktok from './product-add/syncto-channels/Tiktok.vue'
// import PopupShopee from './product-add/syncto-channels/Shopee.vue'
// import PopupLazada from './product-add/syncto-channels/Lazada.vue'

Vue.use(VueScrollactive)

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BasicInformation,
    SalesInformation,
    MediaManagement,
    BButton,
    ValidationObserver,
    BFormCheckbox,
    BFormGroup,
    // BFormInput,
    BCard,
    BCardBody,
    BNav,
    BNavItem,
    // RelatedOptions,
    ShippingDetail,
    // BInputGroup,
    // PopupAddCate,
    // BInputGroupPrepend,
    BFormTags,
    // PopupTiktok,
    // PopupShopee,
    // PopupLazada,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      categorynull: null,
      categoryIdErrorText: null,
      trademarkOptions: null,
      originOptions: null,
      supplierOptions: null,
      brand_id: null,
      origin_id: null,
      supplier_id: null,
      // model: {
      //   // Common
      //   is_active: true,
      //   sku: null,
      //   position: 0,
      //   is_warehouse: false,
      //   is_highlight: false,
      //   // Basic Information
      //   name: null,
      //   short_content: [
      //     {
      //       type: 'table',
      //       isShow: false,
      //       value: [
      //         {
      //           title: null,
      //           content: null,
      //         },
      //       ],
      //     },
      //     {
      //       type: 'content',
      //       isShow: false,
      //       value: null,
      //     },
      //   ],
      //   category_id: null,
      //   category_name: null,
      //   brand_id: null,
      //   origin_id: null,
      //   supplier_id: null,
      //   content: null,
      //   // Sale Information
      //   price: null,
      //   ware_house: [],
      //   // Media
      //   avatar: this.avatarDefault(),
      //   list_thumbnail: [],
      //   // Shipping detail
      //   // weight: null,
      //   // length: null,
      //   // height: null,
      //   // SEO Info
      //   title_page: null,
      //   meta_keyword: [],
      //   meta_description: null,
      //   slug: null,
      // },
      model: {
        // Common
        is_active: true,
        sku: '',
        position: 0,
        is_warehouse: false,
        // Basic Information
        name: '',
        short_content: [
          {
            type: 'table',
            value: [],
          },
          {
            type: 'content',
            value: '',
          },
        ],
        category_id: null,
        category_name: null,
        slug_category_child: null,
        brand_id: null,
        origin_id: null,
        supplier_id: null,
        content: '',
        // Sale Information
        price: null,
        root_price: null,
        bar_code: null,
        ware_house: [],
        variants: [],
        avatar_variants: [],
        // Media
        avatar: this.avatarDefault(),
        video: this.avatarDefault(),
        video_file: null,
        size_guide: this.avatarDefault(),
        size_guide_file: null,
        list_thumbnail: [],
        // Shipping detail
        length: null,
        width: null,
        height: null,
        weight: null,
        // SEO Info
        title_page: '',
        meta_keyword: [],
        meta_description: '',
        slug: '',
        inventory: 0,
        tag: null,
        tag1: [],
        tag2: [],
        tag3: [],
      },
      verticalNav: [
        {
          id: 'basic-information',
          label: 'Basic Information',
        },
        {
          id: 'sale-information',
          label: 'Sales Information',
        },
        {
          id: 'media-management',
          label: 'Media Management',
        },
        {
          id: 'shipping',
          label: 'Shipping',
        },
        {
          id: 'other-information',
          label: 'SEO Optimization',
        },
      ],
      verticalNavSelected: 'basic-information',
      selected: [],
      listSan: [
        {
          id: 'select-san',
          text: 'Tiktok Shop',
        },
        {
          id: 'idspe',
          text: 'Shopee.vn',
        },
        {
          id: 'idlaz',
          text: 'Lazada.vn',
        },
      ],
      verticalListSan: 'select-san',
      // Sync to Tiktok
      modelTiktok: {
        category_id: null,
        category_name: null,
        warehouse_id: null,
        attribute_id: [],
      },
      modelShopee: {
        category_id: null,
        category_name: null,
        attribute_id: [],
        logistic_id: null,
      },
      modelLazada: {
        category_id: null,
        category_name: null,
        attribute_id: [],
      },
      modelDetail: {},
      modelSync: {
        shopee: {
          status: 0,
          text: null,
        },
        lazada: {
          status: 0,
          text: null,
        },
        tiktok: {
          status: 0,
          text: null,
        },
      },
      storage: {},
      isShow: false,
    }
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    'model.variants'() {
      this.generalTag()
    },
  },
  async mounted() {
    this.getStorage()
    this.trademarkOptions = await this.loadTrademarkList()
    this.originOptions = await this.loadOriginList()
    this.supplierOptions = await this.loadSupplierList()
  },
  methods: {
    async getStorage() {
      const res = await this.mixGet('/storages', { limit: 1000 })
      if (res.status) {
        if (res.data) {
          this.storage = res.data.items.find(x => x.is_default === 1)
        }
      }
    },
    generalTag() {
      const tag1 = []
      const tag2 = []
      const tag3 = []
      this.model.variants.map(x => {
        if (!tag1.includes(x.name_bt1)) {
          tag1.push(x.name_bt1)
        }
        if (!tag2.includes(x.name_bt2)) {
          tag2.push(x.name_bt2)
        }
        if (!tag3.includes(x.name_bt3)) {
          tag3.push(x.name_bt3)
        }
      })
      this.model.tag1 = tag1
      this.model.tag2 = tag2
      this.model.tag3 = tag3
    },
    async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (this.model.category_id === null) {
          this.categoryIdErrorText = 'The Category field is required'
        }
        if (success) {
          try {
            this.isShow = true
            const translation = {}
            translation[await this.getEditLanguage()] = {
              name: this.model.name,
              content: this.model.content,
              title_page: this.model.title_page,
              meta_description: this.model.meta_description,
              short_content: JSON.stringify(this.model.short_content),
              meta_keyword: JSON.stringify(this.model.meta_keyword),
            }
            const params = {
              sku: this.model.sku,
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              is_active: Number(this.model.is_active === true ? 1 : 0),
              is_highlight: Number(this.model.is_highlight === true ? 1 : 0),
              is_warehouse: Number(this.model.is_warehouse === true ? 1 : 0),
              position: Number(this.model.position),
              category_id: this.model.category_id,
              price: this.model.price,
              weight: Number(this.model.weight),
              length: Number(this.model.length),
              width: Number(this.model.width),
              height: Number(this.model.height),
              slug: this.model.slug,
              name: this.model.name,
              content: this.model.content,
              title_page: this.model.title_page,
              meta_description: this.model.meta_description,
              short_content: JSON.stringify(this.model.short_content),
              meta_keyword: JSON.stringify(this.model.meta_keyword),
              translation: JSON.stringify(translation),
              warehouse: [],
              variants: [],
              price_json: JSON.stringify({}),
              // push storage_id = 1 for storage default
              storage_id: this.storage.id_number,
              slug_category_child: this.model.slug_category_child,
            }
            const tag = []
            if (this.model.tag1.length > 0) {
              this.model.tag1.map(x => {
                tag.push({
                  color: x,
                })
              })
            }
            if (this.model.tag2.length > 0) {
              this.model.tag2.map(x => {
                tag.push({
                  size: x,
                })
              })
            }
            if (this.model.tag3.length > 0) {
              this.model.tag3.map(x => {
                tag.push({
                  material: x,
                })
              })
            }
            params.tags = JSON.stringify(tag)
            if (this.model.variants && this.model.variants.length > 0) {
              const temp = []
              this.model.variants.map(item => {
                const find = this.model.avatar_variants.find(x => x.name === item.name_bt1)
                if (find) {
                  temp.push({
                    sku: item.sku,
                    barcode: item.barcode,
                    cost_price: item.cost_price,
                    price: item.price,
                    quantity: 0,
                    avatar_id: find.avatar_id,
                    avatar: find.path,
                    name_attribute: JSON.stringify(item.variants),
                    is_active: item.is_active === true ? 1 : 0,
                  })
                }
              })
              params.variants = JSON.stringify(temp)
              params.is_variant = 1
            } else {
              const temp = []
              temp.push({
                sku: this.model.sku,
                barcode: this.model.bar_code,
                cost_price: this.model.root_price,
                price: this.model.price,
                quantity: 0,
                name_attribute: JSON.stringify([
                  {
                    name: 'No Variant', value: 'No Variant',
                  },
                ]),
                is_active: this.model.is_active === true ? 1 : 0,
              })
              params.variants = JSON.stringify(temp)
              params.is_variant = 0
            }
            if (this.model.ware_house && this.model.ware_house.length > 0) {
              this.model.ware_house.map(item => {
                params.warehouse.push(JSON.stringify(item))
              })
            } else {
              params.warehouse.push(
                JSON.stringify({
                  is_warehouse: false,
                  price: 0,
                  quantity: 0,
                  is_active: true,
                  sku: this.model.sku,
                  is_default: true,
                  price_json: JSON.stringify({}),
                }),
              )
            }
            if (this.model.avatar !== null) {
              params.avatar = this.model.avatar
            }
            if (
              // eslint-disable-next-line operator-linebreak
              this.model.video_file &&
              this.model.video_file !== null
            ) {
              const formData = new FormData()
              formData.append('site_id', JSON.parse(localStorage.getItem('siteID')).id)
              formData.append('type_table', 'prod')
              formData.append('type', 'desktop')
              formData.append('avatar', this.model.video_file)
              formData.append('table_field', 'video')
              // const thumbnail = {
              //   site_id: JSON.parse(localStorage.getItem('siteID')).id,
              //   type_table: 'prod',
              //   type: 'desktop',
              //   avatar: this.model.video_file,
              //   table_field: 'video',
              // }
              const resIMG = await Request.post(
                this.$http,
                `${process.env.VUE_APP_API_IMG_URL}/images_only`,
                formData,
              )
              if (resIMG.status === 200) {
                params.video = resIMG.data.data.path
                params.video_id = resIMG.data.data.id
              }
            }
            if (
              // eslint-disable-next-line operator-linebreak
              this.model.size_guide_file &&
              this.model.size_guide_file !== null
            ) {
              const formData = new FormData()
              formData.append('site_id', JSON.parse(localStorage.getItem('siteID')).id)
              formData.append('type_table', 'prod')
              formData.append('type', 'desktop')
              formData.append('avatar', this.model.size_guide_file)
              formData.append('table_field', 'size_guide')
              // const thumbnail = {
              //   site_id: JSON.parse(localStorage.getItem('siteID')).id,
              //   type_table: 'prod',
              //   type: 'desktop',
              //   avatar: this.model.video_file,
              //   table_field: 'video',
              // }
              const resIMG = await Request.post(
                this.$http,
                `${process.env.VUE_APP_API_IMG_URL}/images_only`,
                formData,
              )
              if (resIMG.status === 200) {
                params.size_guide = resIMG.data.data.path
                params.size_guide_id = resIMG.data.data.id
              }
            }
            if (this.model.brand_id !== null) {
              params.trademark_id = this.model.brand_id
            }
            if (this.model.origin_id !== null) {
              params.origin_id = this.model.origin_id
            }
            if (this.model.supplier_id !== null) {
              params.supplier_id = this.model.supplier_id
            }
            if (
              // eslint-disable-next-line operator-linebreak
              this.model.list_thumbnail &&
              this.model.list_thumbnail.length > 0
            ) {
              let listThumbnailId = null
              const listThumbnail = []
              let index = 0
              this.model.list_thumbnail.map(async x => {
                const thumbnail = {
                  site_id: JSON.parse(localStorage.getItem('siteID')).id,
                  type_table: 'prod',
                  type: 'desktop',
                  avatar: x,
                  table_field: 'list_thumbnail',
                }
                const resIMG = await Request.post(
                  this.$http,
                  `${process.env.VUE_APP_API_IMG_URL}/images_only`,
                  thumbnail,
                )
                if (resIMG.status === 200) {
                  if (listThumbnailId) {
                    listThumbnailId += `,${resIMG.data.data.id}`
                  } else {
                    listThumbnailId = resIMG.data.data.id
                  }
                  listThumbnail.push(resIMG.data.data.path)
                }
                index += 1

                if (index === this.model.list_thumbnail.length) {
                  params.list_thumbnail_id = listThumbnailId
                  params.list_thumbnail = JSON.stringify(listThumbnail)
                  this.handleCreate(params)
                }
              })
            } else {
              this.handleCreate(params)
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    async handleCreate(params) {
      if (
        params.warehouse !== undefined &&
        (params.warehouse.filter(val => JSON.parse(val).price_error)
          .length > 0 ||
          params.warehouse.filter(val => JSON.parse(val).sku_error)
            .length > 0)
      ) {
        // this.model.isShow = false
        return
      }
      const res = await Request.post(
        this.$http,
        `${process.env.VUE_APP_API_URL}/v2/product`,
        params,
      )
      if (res.status === 200) {
        if (res.data.status) {
          this.isShow = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Congratulation !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Create Product success',
            },
          })
          this.$router.push('/product/list')
          // setTimeout(async () => {
          //   if (this.modelTiktok.category_id || this.modelShopee.category_id || this.modelLazada.category_id) {
          //     this.$toast({
          //       component: ToastificationContent,
          //       position: 'top-center',
          //       props: {
          //         title: 'Đồng bộ...',
          //         icon: 'UploadCloudIcon',
          //         variant: 'info',
          //         text: 'Bắt đầu đồng bộ sản phẩm vừa được tạo lên sàn TMĐT',
          //       },
          //     })
          //     const siteId = JSON.parse(localStorage.getItem('siteID')).id
          //     const resDetail = await Request.get(
          //       this.$http,
          //       `${process.env.VUE_APP_API_URL}/product/${res.data.data.id_string}?site_id=${siteId}`,
          //     )
          //     if (resDetail.status === 200) {
          //       if (resDetail.data.status) {
          //         this.modelDetail = {
          //           id: resDetail.data.data.id_number,
          //           avatar: resDetail.data.data.avatar,
          //         }
          //         if (this.modelShopee.category_id) {
          //           this.handleSyncToShopee(this.modelDetail)
          //         }
          //       } else {
          //         this.$toast({
          //           component: ToastificationContent,
          //           position: 'top-right',
          //           props: {
          //             title: 'Opps! Something wrong',
          //             icon: 'AlertOctagonIcon',
          //             variant: 'danger',
          //             text: String(this.showError(res.data.error, ',')),
          //           },
          //         })
          //       }
          //     }
          //     if (this.modelTiktok.category_id !== null) {
          //       this.handleSyncToTiktok(res.data.data)
          //     }
          //     if (this.modelLazada.category_id) {
          //       this.handleSyncToLazada(res.data.data)
          //     }
          //     this.$bvModal.show('modal-popup-sync-product-status')
          //   }
          // }, 1000)
          this.isShow = false
        } else {
          this.isShow = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(this.showError(res.data.error, ',')),
            },
          })
        }
      }
    },
    async handleSyncToShopee(data) {
      try {
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const paramsImg = {
          site_id: siteId,
          images: [data.avatar],
        }
        const resImg = await Request.post(
          this.$http,
          `${process.env.VUE_APP_API_URL}/shopee/upload_image`, paramsImg,
        )
        if (resImg.data.status) {
          const url = resImg.data.data[0].shopee_image_url.split('/')
          // const siteId = JSON.parse(localStorage.getItem('siteID')).id
          const params = {
            site_id: siteId,
            product_id: data.id,
            category_id: this.modelShopee.category_id,
            logistic_id: this.modelShopee.logistic_id,
            image_id_list: JSON.stringify([url[resImg.data.data[0].shopee_image_url.split('/').length - 1]]),
            brand_id: 0,
          }
          const res = await Request.post(
            this.$http,
            `${process.env.VUE_APP_API_URL}/shopee/v2/sync_to_shopee`, params,
          )
          if (res.status === 200) {
            if (res.data.status) {
              this.modelSync.shopee.status = 1
              this.modelSync.shopee.text = 'Đồng bộ thành công'
            } else {
              this.modelSync.shopee.status = 2
              // eslint-disable-next-line prefer-destructuring
              this.modelSync.shopee.text = res.data.error[0]
            }
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handleSyncToLazada(data) {
      try {
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        // const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const params = {
          site_id: siteId,
          product_id: data.id,
          category_id: this.modelLazada.category_id,
        }
        const res = await Request.post(
          this.$http,
          `${process.env.VUE_APP_API_URL}/lazada/v2/sync_to_lazada`, params,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.modelSync.lazada.status = 1
            this.modelSync.lazada.text = 'Đồng bộ thành công'
          } else {
            this.modelSync.lazada.status = 2
            this.modelSync.lazada.text = res.data.error
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handleSyncToTiktok(data) {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      const params = {
        site_id: siteId,
        product_id: data.id,
        category_id: this.modelTiktok.category_id,
        attribute_ids: JSON.stringify(this.modelTiktok.attribute_id),
        warehouse_id: this.modelTiktok.warehouse_id,
        is_cod_open: 'false',
      }
      const res = await Request.post(
        this.$http,
        `${process.env.VUE_APP_API_URL}/tiktok/v1/create_product`, params,
      )
      if (res.status === 200) {
        if (res.data.status) {
          this.modelSync.tiktok.status = 1
          this.modelSync.tiktok.text = 'Đồng bộ thành công'
        } else {
          this.modelSync.tiktok.status = 2
          this.modelSync.tiktok.text = res.data.error
        }
      }
    },
    closeModal() {},
    changeBrand(data) {
      this.brand_id = data
    },
    changeOrigin(data) {
      this.origin_id = data
    },
    changeSupplier(data) {
      this.supplier_id = data
    },
    async loadTrademarkList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/trademarks?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            return await this.getDataTranslation(res.data.data.items, true)
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return false
    },
    async loadOriginList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/origins?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            return await this.getDataTranslation(res.data.data.items, true)
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return false
    },
    async loadSupplierList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/suppliers?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.supplierOptions = res.data.data.items
            return await this.getDataTranslation(res.data.data.items, true)
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return false
    },
    pageScrollTo(target) {
      this.verticalNavSelected = target
      const targetPos = this.$refs[target].offsetTop
      window.scrollTo({
        top: targetPos,
        behavior: 'smooth',
      })
    },
  },
}
</script>
<style lang="scss" scoped>
#select-san-block{
  border: none;
  margin-bottom: 2rem;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  padding: 1.5rem;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(34, 41, 47, 0.125);
  border-radius: 0.428rem;
  .title-san{
    font-weight: 700;
    margin-bottom: 8px;
    font-size: 16px;
  }
}

input:read-only {
    background: #fff;
}
.list-channel:not(:last-child){
  margin-bottom: 1rem;
}
.list-san{
  .one-item{
    display: block;
  }
}
.right-fixed-menu {
  width: 100%;
  max-width: 350px;
  right: 30px;
  a {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 14px;
    margin: 8px 0;
    position: relative;
    color: var(--secondary);
    &:before {
      content: '';
      height: 100%;
      width: 1.8px;
      background: var(--secondary);
      position: absolute;
      left: 0;
      top: 0;
    }
    &.active {
      color: var(--primary);
      font-weight: bold;
      &:before {
        width: 2px;
        background: var(--primary);
      }
    }
  }
}
.nav-link {
  &:not(:hover) {
    color: inherit;
  }
  &.active {
    color: #ff9f43;
  }
}
</style>

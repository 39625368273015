<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div>
    <b-form-group
      v-if="variants.length == 0"
      :label="`*`+$t('Price')+`:`"
      label-cols-md="4"
    >
      <validation-provider
        #default="{ errors }"
        name="Price"
        rules="required"
      >
        <b-input-group
          class="input-group-merge"
          :state="errors.length > 0 ? 'error' : null"
        >
          <b-form-input
            v-model="value.price"
            type="number"
            placeholder=""
          />
          <b-input-group-append is-text>
            {{ currency }}
          </b-input-group-append>
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <b-form-group
      v-if="variants.length == 0"
      :label="`*`+$t('Root price')+`:`"
      label-cols-md="4"
    >
      <validation-provider
        #default="{ errors }"
        name="root price"
        rules="required"
      >
        <b-input-group
          class="input-group-merge"
          :state="errors.length > 0 ? 'error' : null"
        >
          <b-form-input
            v-model="value.root_price"
            type="number"
            placeholder=""
          />
          <b-input-group-append is-text>
            {{ currency }}
          </b-input-group-append>
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <b-form-group
      v-if="variants.length == 0"
      :label="`*`+$t('Barcode')+`:`"
      label-cols-md="4"
    >
      <validation-provider
        #default="{ errors }"
        name="Barcode"
        rules="required"
      >
        <b-form-input
          v-model="value.bar_code"
          type="text"
          placeholder=""
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <!-- <b-form-group
      :label="`*`+$t('Inventory')+`:`"
      label-cols-md="4"
    >
      <validation-provider
        #default="{ errors }"
        name="inventory"
        rules="required"
      >
        <b-input-group
          class="input-group-merge"
        >
          <b-form-input
            v-model="value.inventory"
            :state="errors.length > 0 ? false : null"
            type="number"
            :placeholder="$t('inventory')"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group> -->

    <b-form-group
      :label="$t('Variants')+`:`"
      label-cols-md="4"
    >
      <b-card
        v-for="(variant, variantIndex) in variants"
        :key="variant.id"
        :title="`${ $t('Variant') } ${variantIndex + 1}`"
        class="border mb-1"
      >
        <b-card-body class="p-0">
          <b-form-group label="Tên biến thể">
            <b-form-input
              v-model="variants[variantIndex].name"
              placeholder="Nhập tên biến thể, ví dụ: Màu sắc, Kích thước"
            />
          </b-form-group>
          <b-form-group
            label="Danh sách biến thể"
          >
            <div
              class="d-flex flex-column"
              style="gap: 0.5rem;"
            >
              <b-input-group
                v-for="(child, childIndex) in variant.children"
                :key="child.id"
              >
                <b-form-input
                  v-model="variants[variantIndex].children[childIndex]"
                  placeholder="Nhập tên biến thể, ví dụ: Trắng, Đỏ"
                  @blur="variantsGenerate()"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-danger"
                    :class="{'disabled': variants[variantIndex].children.length === 1}"
                    @click="variantChildRemove(variantIndex, childIndex)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>

              <div
                class="fade-browse"
                @click="variantChildAdd(variantIndex)"
              >
                <feather-icon
                  icon="PlusCircleIcon"
                  class="mr-25"
                />
                {{ $t('Add more') }}
              </div>
            </div>
          </b-form-group>
          <div class="text-right">
            <b-button
              variant="danger"
              @click="variantRemove(variantIndex)"
            >
              <feather-icon icon="Trash3Icon" />
              Xóa biến thể
            </b-button>
          </div>
        </b-card-body>
      </b-card>
      <!-- <app-collapse
        type="margin"
      >
        <app-collapse-item
          v-for="(variant, variantIndex) in variants"
          :key="variant.id"
          :title="`${ $t('Variant') } ${variantIndex + 1}`"
          :visible="true"
        >
          <b-form-group label="Tên biến thể">
            <b-form-input
              v-model="variants[variantIndex].name"
              placeholder="Nhập tên biến thể, ví dụ: Màu sắc, Kích thước"
            />
          </b-form-group>
          <b-form-group
            label="Danh sách biến thể"
          >
            <div
              class="d-flex flex-column"
              style="gap: 0.5rem;"
            >
              <b-input-group
                v-for="(child, childIndex) in variant.children"
                :key="child.id"
              >
                <b-form-input
                  v-model="variants[variantIndex].children[childIndex]"
                  placeholder="Nhập tên biến thể, ví dụ: Trắng, Đỏ"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-danger"
                    :class="{'disabled': variants[variantIndex].children.length === 1}"
                    @click="variantChildRemove(variantIndex, childIndex)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>

              <div
                class="fade-browse"
                @click="variantChildAdd(variantIndex)"
              >
                <feather-icon
                  icon="PlusCircleIcon"
                  class="mr-25"
                />
                {{ $t('Add more') }}
              </div>
            </div>
          </b-form-group>
          <div class="text-right">
            <b-button
              variant="danger"
              @click="variantRemove(variantIndex)"
            >
              <feather-icon icon="Trash3Icon" />
              Xóa biến thể
            </b-button>
          </div>
        </app-collapse-item>
      </app-collapse> -->

      <div
        v-if="variants.length < 3"
        :class="['fade-browse', {'mt-1': variants.length > 0}]"
        @click="variantAdd()"
      >
        <feather-icon
          icon="PlusCircleIcon"
          class="mr-25"
        />
        {{ $t('Add variants') }}
      </div>
    </b-form-group>

    <b-form-group v-if="variantsTable.length > 0">
      <b-form-group
        label-cols-md="4"
        :label="$t('Applies to all variants')"
      >
        <div
          class="d-flex flex-wrap justify-content-between"
          style="gap: 8px;"
        >
          <div
            class="d-flex"
            style="gap: 8px;"
          >
            <b-input-group
              class="input-group-merge"
              style="max-width: 210px;"
            >
              <b-form-input
                v-model="applyOn.price"
                type="number"
                :placeholder="$t('Price')"
              />
              <b-input-group-append is-text>
                {{ currency }}
              </b-input-group-append>
            </b-input-group>
            <!-- <b-input-group
              class="input-group-merge"
              style="max-width: 210px;"
            >
              <b-form-input
                v-model="applyOn.quantity"
                type="number"
                :placeholder="$t('Inventory')"
              />
            </b-input-group> -->
            <b-input-group
              class="input-group-merge"
              style="max-width: 210px;"
            >
              <b-form-input
                v-model="applyOn.sku"
                :placeholder="$t('SKU')"
              />
            </b-input-group>
            <b-input-group
              class="input-group-merge"
              style="max-width: 210px;"
            >
              <b-form-input
                v-model="applyOn.barcode"
                :placeholder="$t('Barcode')"
              />
            </b-input-group>
          </div>
          <b-button
            variant="primary"
            @click="applyAll()"
          >
            {{ $t('Apply') }}
          </b-button>
        </div>
      </b-form-group>

      <div class="table-responsive variant-table">
        <table class="table table-bordered">
          <thead class="thead-light text-center">
            <tr>
              <th
                v-for="(variant, index) in variantsTable[0].variants"
                :key="index"
                class="variant-cell"
              >{{ variant.name }}</th>
              <th class="variant-cell-edit">
                {{ $t('Cost Price') }}
              </th>
              <th class="variant-cell-edit">
                {{ $t('Price') }}
              </th>
              <!-- <th class="variant-cell-edit">
                {{ $t('Inventory') }}
              </th> -->
              <th class="variant-cell-edit">
                {{ $t('SKU') }}
              </th>
              <th class="variant-cell-edit">
                {{ $t('Barcode') }}
              </th>
              <th class="variant-cell-edit">
                {{ $t('Active') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(variantItem, index) in variantsTable"
              :key="variantItem.id"
            >
              <td
                :rowspan="variant1ChildrenLength"
                :class="['variant-cell', {'d-none': !isFirstVariant(index)}]"
              >
                <!-- {{ variantItem[0].value || `${$t('Type')} 1` }} -->
                {{ variantItem.variants[0].value || `${$t('Type')} 1` }}
              </td>
              <td
                v-if="variantItem.variants.length >= 2"
                :rowspan="variant2ChildrenLength"
                :class="['variant-cell', {'d-none': !isSecondVariant(index)}]"
              >
                <!-- {{ variantItem[1].value || `${$t('Type')} 2` }} -->
                {{ variantItem.variants[1].value || `${$t('Type')} 2` }}
              </td>
              <td
                v-if="variantItem.variants.length === 3"
                class="variant-cell"
              >
                <!-- {{ variantItem[2].value || `${$t('Type')} 3` }} -->
                {{ variantItem.variants[2].value || `${$t('Type')} 3` }}
              </td>
              <td class="variant-cell-edit p-0">
                <b-form-input
                  v-model="variantItem.cost_price"
                  type="number"
                  class="text-center"
                  :placeholder="$t('Price')"
                />
              </td>
              <td class="variant-cell-edit p-0">
                <b-form-input
                  v-model="variantItem.price"
                  type="number"
                  class="text-center"
                  :placeholder="$t('Price')"
                />
              </td>
              <!-- <td class="variant-cell-edit p-0">
                <b-form-input
                  v-model="variantItem.quantity"
                  type="number"
                  class="text-center"
                  :placeholder="$t('Quantity')"
                />
              </td> -->
              <td class="variant-cell-edit p-0">
                <b-form-input
                  v-model="variantItem.sku"
                  class="text-center"
                  :placeholder="$t('SKU')"
                />
              </td>
              <td class="variant-cell-edit p-0">
                <b-form-input
                  v-model="variantItem.barcode"
                  class="text-center"
                  :placeholder="$t('Barcode')"
                />
              </td>
              <td class="variant-cell-edit p-0">
                <b-form-checkbox
                  v-model="variantItem.is_active"
                  checked="true"
                  class="custom-control-success text-center"
                  name="check-button"
                  switch
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <b-form-group
        label-cols-md="4"
        :label="$t('Photos for Variation')"
      >
        <div
          class="review-img-list"
        >
          <div
            v-for="(item, index) in variantsThumbnail"
            :key="item.name"
          >
            <div class="review-img-avatar">
              <b-button
                variant="danger"
                class="review-img__del_avatar btn-icon rounded-circle"
              >
                <feather-icon icon="Edit3Icon" />
              </b-button>
              <b-form-file
                accept=".jpg, .png, .gif, .jpeg"
                class="d-flex align-items-center justify-content-center img-box"
                @change="handlerChangeAvatar($event, index)"
              />
              <b-aspect aspect="1:1">
                <b-img-lazy
                  :src="item.avatar || '/default-avatar.png'"
                  fluid
                  thumbnail
                  rounded
                  class="w-100"
                  style="object-fit: contain;"
                />
              </b-aspect>
            </div>
            <div class="text-center">
              <small>{{ item.name }}</small>
            </div>
          </div>
        </div>
      </b-form-group>
    </b-form-group>
    <!-- <b-form-group label="* Quantity:" label-cols-md="12">
      <validation-provider
        #default="{ errors }"
        name="Quantity"
        rules="required"
      >
        <b-form-input
          v-model="value.quantity"
          :state="errors.length > 0 ? false : null"
          type="number"
          placeholder=""
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
      <b-form-checkbox
        v-model="value.sell_a_sLot"
        value="sellAsLot"
        class="custom-control-primary mt-1"
      >
        Sell as lot
      </b-form-checkbox>
    </b-form-group> -->
    <!-- <Variation
      v-model="value"
      :currency="currency"
    /> -->
    <!-- <WhoseSale v-model="value" /> -->
    <!-- <b-row>
        <b-col md="12">
          <b-form-group label="* Payment options:" label-cols-md="12">
            <validation-provider
              #default="{ errors }"
              name="Payment options"
              rules="required"
            >
              <b-form-checkbox
                v-model="value.payment"
                value="paypal"
                class="custom-control-primary"
                style="margin-top: 8px;"
              >
                PayPal
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <b-form-group
              v-if="value.payment"
              label="Email address for receiving payment:"
              label-cols-md="6"
              class="mt-1"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  v-model="value.email_paypal"
                  :state="errors.length > 0 ? false : null"
                  type="email"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Return options:" label-cols-md="12">
            <b-form-checkbox
              v-model="value.return_options"
              value="IRA"
              class="custom-control-primary"
              style="margin-top: 8px;"
            >
              International returns accepted
            </b-form-checkbox>
            <template v-if="value.return_options">
              <b-form-group
                label="After receiving the item, your buyer should contact you within:"
                label-cols-md="9"
                class="mt-1"
              >
                <v-select
                  v-model="value.return_time"
                  label="title"
                  :options="returnTimesOptions"
                />
              </b-form-group>
              <b-form-group
                label="Return shipping fee will be paid by:"
                label-cols-md="4"
              >
                <v-select
                  v-model="value.return_paid"
                  label="title"
                  :options="returnShippingFeePaidOptions"
                />
              </b-form-group>
              <b-form-checkbox
                v-model="value.exchange"
                value="ROEA"
                class="custom-control-primary"
              >
                Replacement or exchange available
              </b-form-checkbox>
            </template>
          </b-form-group>
        </b-col>
      </b-row> -->
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BInputGroup,
  // BCollapse,
  BCard,
  BCardBody,
  // BCardText,
  // BInputGroupPrepend,
  BInputGroupAppend,
  // BFormCheckbox,
  BButton,
  BFormFile,
  BImgLazy,
  BAspect,
  BFormCheckbox,
} from 'bootstrap-vue'
// import Cleave from 'vue-cleave-component'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Request from '@/plugins/service/index'
// import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
// import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
// import Variation from '@/views/pages/product/product-add/components/Variation.vue'
// import WhoseSale from '@/views/pages/product/product-add/components/WhoseSale.vue'

export default {
  components: {
    ValidationProvider,
    BFormInput,
    BFormGroup,
    BInputGroup,
    // BCollapse,
    BCard,
    BCardBody,
    // BCardText,
    // BInputGroupPrepend,
    BInputGroupAppend,
    // BFormCheckbox,
    BButton,
    // Variation,
    // WhoseSale,
    // AppCollapse,
    // AppCollapseItem,
    BFormFile,
    BImgLazy,
    BAspect,
    BFormCheckbox,
    // Cleave,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      required,
      email,
      returnShippingFeePaidOptions: [
        { title: 'Buyer' },
        { title: 'Seller (Free Returns)' },
      ],
      returnTimesOptions: [{ title: '30 days' }, { title: '60 days' }],
      currency: '',
      variants: [],
      variantsTable: [],
      variantsTableOld: [],
      variant1ChildrenLength: 0,
      variant2ChildrenLength: 0,
      applyOn: {
        price: null,
        quantity: null,
        sku: null,
        barcode: null,
      },
      variantsThumbnail: [],
      tempVariantsThumbnail: [],
    }
  },
  computed: {
    // warehouses() {
    //   return this.variantsGenerate()
    // },
    // isFirstVariant() {

    // },
  },
  mounted() {
    this.currency = JSON.parse(localStorage.getItem('siteID')).currency
  },
  methods: {
    onEditorChange({ html }) {
      this.value.product_description = html
      this.product_description_length = html.length
    },
    variantAdd() {
      const nextId = this.variants.length
      if (nextId < 3) {
        this.variants.push({
          id: Date.now(),
          name: '',
          children: [''],
        })
      }
      this.variantsGenerate()
    },
    variantRemove(index) {
      this.variants.splice(index, 1)
      this.variantsGenerate()
    },
    variantChildAdd(variantIndex) {
      this.variants[variantIndex].children.push('')
      this.variantsGenerate()
    },
    variantChildRemove(variantIndex, index) {
      this.variants[variantIndex].children.splice(index, 1)
      // this.variantsThumbnail[variantIndex].splice(index, 1)
      this.variantsGenerate()
    },
    variantsGenerate() {
      // eslint-disable-next-line prefer-destructuring
      const temp = JSON.parse(JSON.stringify(this.variants))
      const variants = []
      temp.map(val => {
        const childs = []
        val.children.map(val2 => {
          if (val2) {
            if (childs.includes(val2) === false) {
              childs.push(val2)
            }
          }
          return true
        })
        variants.push({
          children: childs,
          id: val.id,
          name: val.name,
        })
        return true
      })
      const result = []
      let variant0Children = []
      let variant1Children = []
      let variant2Children = []
      this.oldVariants = this.variantsTable

      if (variants[0]) variant0Children = variants[0].children
      if (variants[1]) variant1Children = variants[1].children
      if (variants[2]) variant2Children = variants[2].children

      this.variant1ChildrenLength = (variant1Children.length || 1) * (variant2Children.length || 1)
      this.variant2ChildrenLength = variant2Children.length || 1

      for (let i0 = 0; i0 < variant0Children.length; i0 += 1) {
        if (variant1Children.length === 0) {
          result.push({
            id: `${i0}${Date.now()}`,
            variants: [
              {
                name: `${variants[0].name}`,
                value: `${variant0Children[i0]}`,
              },
            ],
            tempkey: `${variant0Children[i0]}`,
            price: 0,
            name_bt1: variant0Children[i0],
            name_bt2: null,
            name_bt3: null,
            quantity: 0,
            sku: null,
            barcode: null,
            is_active: true,
            cost_price: 0,
          })
        } else {
          for (let i1 = 0; i1 < variant1Children.length; i1 += 1) {
            if (variant2Children.length === 0) {
              result.push({
                id: `${i0}${i1}${Date.now()}`,
                variants: [
                  {
                    name: `${variants[0].name}`,
                    value: `${variant0Children[i0]}`,
                  },
                  {
                    name: `${variants[1].name}`,
                    value: `${variant1Children[i1]}`,
                  },
                ],
                tempkey: `${variant0Children[i0]}&${variant1Children[i1]}`,
                name_bt1: variant0Children[i0],
                name_bt2: variant1Children[i1],
                name_bt3: null,
                price: 0,
                quantity: 0,
                sku: null,
                barcode: null,
                is_active: true,
                cost_price: 0,
              })
            } else {
              for (let i2 = 0; i2 < variant2Children.length; i2 += 1) {
                result.push({
                  id: `${i0}${i1}${i2}${Date.now()}`,
                  variants: [
                    {
                      name: `${variants[0].name}`,
                      value: `${variant0Children[i0]}`,
                    },
                    {
                      name: `${variants[1].name}`,
                      value: `${variant1Children[i1]}`,
                    },
                    {
                      name: `${variants[2].name}`,
                      value: `${variant2Children[i2]}`,
                    },
                  ],
                  tempkey: `${variant0Children[i0]}&${variant1Children[i1]}&${variant2Children[i2]}`,
                  name_bt1: variant0Children[i0],
                  name_bt2: variant1Children[i1],
                  name_bt3: variant2Children[i2],
                  price: 0,
                  quantity: 0,
                  sku: null,
                  barcode: null,
                  is_active: true,
                  cost_price: 0,
                })
              }
            }
          }
        }
      }

      result.map((val, index) => {
        const findData = this.oldVariants.find(x => x.tempkey === val.tempkey)
        if (findData) {
          result[index] = findData
        }
        return true
      })

      this.variantsTable = result
      this.value.variants = result
      this.variantsThumbnailGenerate(result)
      return result
    },
    variantsThumbnailGenerate(data) {
      const variant1List = [...new Set(data.map(item => item.variants[0].value))]
      if (data.length < this.tempVariantsThumbnail.length) {
        const indexDelete = this.tempVariantsThumbnail.findIndex(({ name: nameID }) => !variant1List.some(y => nameID === y))
        this.variantsThumbnail.splice(indexDelete, 1)
      }
      // const result = []
      // eslint-disable-next-line array-callback-return
      variant1List.map(item => {
        const isExist = this.tempVariantsThumbnail.find(x => x.name === item)
        if (!isExist) {
          this.variantsThumbnail.push({
            name: item,
            path: null,
            avatar: null,
            avatar_id: null,
          })
        }
      })
      // this.variantsThumbnail = result
      this.tempVariantsThumbnail = this.variantsThumbnail
      this.value.avatar_variants = this.variantsThumbnail
    },
    isFirstVariant(index) {
      let res = true
      if (index % this.variant1ChildrenLength !== 0) {
        res = false
      } else {
        res = true
      }
      return res
    },
    isSecondVariant(index) {
      let res = true
      if (index % this.variant2ChildrenLength !== 0) {
        res = false
      } else {
        res = true
      }
      return res
    },
    applyAll() {
      // eslint-disable-next-line array-callback-return
      this.variantsTable.map(item => {
        // eslint-disable-next-line no-param-reassign
        item.price = this.applyOn.price
        // eslint-disable-next-line no-param-reassign
        item.quantity = this.applyOn.quantity
        // eslint-disable-next-line no-param-reassign
        item.sku = this.applyOn.sku
        // eslint-disable-next-line no-param-reassign
        item.barcode = this.applyOn.barcode
      })
    },
    async handlerChangeAvatar(input, index) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = async e => {
        // eslint-disable-next-line no-param-reassign
        // this.variantsThumbnail[index].avatar = e.target.result
        const param = {
          site_id: JSON.parse(localStorage.getItem('siteID')).id,
          type_table: 'w_h',
          type: 'desktop',
          avatar: e.target.result,
          table_field: 'avatar_variant',
        }
        const resIMG = await Request.post(
          this.$http,
          `${process.env.VUE_APP_API_IMG_URL}/images_only`,
          param,
        )
        if (resIMG.status === 200) {
          this.variantsThumbnail[index].path = resIMG.data.data.path
          this.variantsThumbnail[index].avatar = resIMG.data.data.image
          this.variantsThumbnail[index].avatar_id = resIMG.data.data.id
        }
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-gray {
  background: #f6f6f6;
  padding: 12px 24px;
}
table tr td {
  border: 1px solid #ebe9f1;
}
.fade-browse {
  border: 1px dashed var(--primary);
  color: var(--primary);
  border-radius: 4px;
  padding: 8px 12px;
  text-align: center;
  position: relative;
  cursor: pointer;

  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
.variant-table {
  .table {
    th,
    td {
      padding: 0.5rem 0.75rem;
    }
    th {
      padding: 0.75rem;
      vertical-align: middle;
    }
  }
  .variant-cell {
    min-width: 130px;
    max-width: 140px;

    &-edit {
      min-width: 120px;
      max-width: 130px;

      .form-control {
        padding: 0.5rem 0.75rem;
        min-height: 40px;
        border-radius: 0;

        &:not(:focus) {
          border-color: transparent;
        }
      }
    }
  }
}
.review-img,
.review-img-avatar {
  position: relative;

  &::before {
    position: absolute;
    content: 'Drag to Sort';
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: rgba(100, 100, 100, 0.75);
    left: 1rem;
    right: 1rem;
    bottom: 0;
    top: 0;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    pointer-events: none;
  }
  &__del {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    &::before,
    .review-img__del,
    .review-img__del_avatar {
      opacity: 1;
    }
  }
}
.review-img-avatar {
  &::before {
    content: '';
    width: 100%;
    left: 0;
  }
  .review-img__del_avatar {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 2;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }
}
.review-img-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 8px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .img-box {
    position: absolute;
    z-index: 2;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"title-block"},[_c('span',[_vm._v("*")]),_c('div',{staticClass:"title"},[_vm._v(" Khối lượng kiện hàng sau khi đóng gói (đơn vị: kilogram). Vd: nếu gói hàng cân nặng 200 gram thì vui lòng điền 0.2 vào ô bên dưới ")])])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Weight","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":"Khối lượng (kg)"},model:{value:(_vm.value.weight),callback:function ($$v) {_vm.$set(_vm.value, "weight", $$v)},expression:"value.weight"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"title-block"},[_c('span',[_vm._v("*")]),_c('div',{staticClass:"title"},[_vm._v(" Kích thước hàng (cm) ")])])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Width","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":"Chiều dài (cm)"},model:{value:(_vm.value.width),callback:function ($$v) {_vm.$set(_vm.value, "width", $$v)},expression:"value.width"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"x-icon"},[_vm._v(" X ")])],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Length","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":"Chiều rộng (cm)"},model:{value:(_vm.value.length),callback:function ($$v) {_vm.$set(_vm.value, "length", $$v)},expression:"value.length"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"x-icon"},[_vm._v(" X ")])],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Height","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number","placeholder":"Chiều cao (cm)"},model:{value:(_vm.value.height),callback:function ($$v) {_vm.$set(_vm.value, "height", $$v)},expression:"value.height"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }